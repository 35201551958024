import React, {Component} from 'react';

export default class TextMediaImage extends Component {
  static defaultProps = {
    imageTitle: '',
    srcImage: '',
    classNames: ''
  };

  render() {
    let {srcImage, classNames, imageTitle} = this.props;

    return (
        <img src={srcImage} className={`${classNames}`} alt={imageTitle}/>
    );
  }
}
