import React, {Component} from 'react';

export default class TextModule extends Component {
  static defaultProps = {
    title: '',
    description: '',
    bulletList: '',
    classNames: '',
    textWidth: ''
  };

  render() {
    let {textWidth, title, description} = this.props;

    return (
        <div className={`text-module${textWidth === 'half' ? ' half-width' : ' full-width'}`}>

          <div className={`text-container`}>
            <h4 className="text-media-headline">{title}</h4>
            <div className={"text-description"}>
              {description}
            </div>
          </div>
        </div>
    );
  }
}
