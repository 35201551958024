import React, {Component} from 'react';
import ButtonTwo from "./ButtonTwo";
import ImageLink from "./ImageLink";

export default class Footer extends Component {
  static defaultProps = {
    cat: 'magazine',
    claim: '',
    titleImage: '',
    srcImage: '',
    articleImage: ''
  };

  render() {
    /*    let {nextClaim} = this.props.nextSubpage.claim;

        function createLineBreaks() {
          return {__html: nextClaim};
        }*/

    return (
        <div className={"footer"}>
          <div className="euronet-link">
            <a href="https://www.euronet-ag.de" target="_blank" rel="noopener noreferrer">
              Mehr erfahren:
              <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 200 47">
                <title>Euronet AG</title>
                <g className="icon">
                  <path fill="#ffb300" className="bottom-right" d="m37.3 8.9c3.4 3.5 5 8.5 4.1 13.3-1 5.4-5 10-10.2 11.6-5.5 1.8-11.7.1-15.6-4.1-2.1-2.3-3.5-5.2-3.9-8.2-3.2 4.1-4 9.7-2.2 14.6 1.6 4.3 5.2 7.6 9.6 9 2.7.9 5.7.9 8.4.2 4.5-1.2 8.5-3.8 11.4-7.5 2.8-3.6 4.5-8 4.7-12.5.1-2.3-.1-4.5-.7-6.7-1-3.8-2.9-7-5.6-9.7"></path>
                  <path fill="#fb7d28" className="top-right" d="m.8 18.5c1.2-4.6 4.7-8.4 9.1-10.1 5.1-1.9 11-.9 15.1 2.6 2.3 2 4 4.6 4.8 7.5.8 2.9.7 5.9-.2 8.8-.9 2.8-2.7 5.3-5.1 7.1 5.6.8 11.2-1.7 14.4-6.4 2.6-3.8 3.4-8.6 2-13-1.4-4.6-5-8-9.2-10.2-4-2-8.7-2.8-13.3-2.1-4.5.7-8.8 2.9-12 6.1-2.8 2.8-4.6 6.1-5.6 9.7"></path>
                  <path fill="#c30" className="left" d="m27.4 45.4c-4.5 1.2-9.5.2-13.2-2.8-4.2-3.3-6.4-8.7-5.6-14 .9-5.8 5.1-10.7 10.8-12.3 3.2-.9 6.8-.7 9.9.5-2-4.8-6.3-8.3-11.4-9.1-4.5-.8-9.3.6-12.7 3.7-1.7 1.5-3.1 3.5-3.9 5.6-.9 2.2-1.2 4.6-1.3 7-.1 4.6 1.3 9.1 4 12.9 2.7 3.8 6.6 6.7 11 8.1.4.1.8.2 1.2.4 3.8 1 7.5.9 11.2 0"></path>
                </g>

                <g fill="#181715" className="euronet">
                  <path d="m96.3 6.6v12.2c0 6.5-3.7 10.4-10.1 10.4-6.3 0-10-3.9-10-10.4v-12.2h5.4v12.9c0 3.6 1.3 5 4.6 5 3.3 0 4.7-1.5 4.7-5v-12.9h5.4"></path>
                  <path d="m112 6.2v4.6c-3 0-5 .5-5.7.8v17.2h-5.3v-20.2c-.1 0 4.6-2.2 11-2.4"></path>
                  <path d="m129.4 17.7c0-5.9-2-7-5.2-7-3.2 0-5.2 1.2-5.2 7s2 7 5.2 7c3.2.1 5.2-1.1 5.2-7m5.3 0c0 7.4-3.7 11.5-10.5 11.5-6.8 0-10.5-4.1-10.5-11.5s3.7-11.5 10.5-11.5c6.8.1 10.5 4.1 10.5 11.5"></path>
                  <path d="m158.3 15.5v13.4h-5.3v-13.9c0-2-.4-4.1-5.3-4.1-1.5 0-2.9.2-4.1.4v17.5h-5.3v-20.1c.2-.1 4.5-2.5 10.2-2.5 6.1 0 9.8 3.5 9.8 9.3"></path>
                  <path d="m190.8 11.8v10.3c0 2 .8 2.4 2.7 2.4 1 0 3.9-.8 4.7-1.1l1.8 3.7c-1.1.4-4.3 1.9-7.5 1.9-4.4 0-7.1-2.8-7.1-7.4v-21.6h5.3v7.2h8.9v4.6h-8.8"></path>
                  <path d="m171.7 10.6c2.9 0 4.2 2.7 4.5 5.5h-9c.4-4 1.9-5.5 4.5-5.5m7.2 12.9c-1.6.6-3.7 1.1-6 1.1-2.6 0-5.3-.6-5.7-4.9h14.3c.1-.9.1-1.8.1-2.8 0-5.6-3-10.8-9.9-10.8-7.3 0-9.9 5.1-9.9 11.8 0 9.4 5.9 11.2 10.3 11.2 3.6 0 6.3-.9 8.4-2v-.1l-1.6-3.5z"></path>
                  <path d="m62.3 10.6c2.8 0 4.2 2.7 4.5 5.5h-9c.4-4 1.9-5.5 4.5-5.5m7.2 12.9c-1.6.6-3.7 1.1-6 1.1-2.6 0-5.3-.6-5.7-4.9h14.3c.1-.9.1-1.8.1-2.8 0-5.6-3-10.8-9.9-10.8-7.3 0-9.9 5.1-9.9 11.8 0 9.4 5.9 11.2 10.3 11.2 3.6 0 6.3-.9 8.4-2v-.1l-1.6-3.5z"></path>
                </g>

                <g fill="#181715" className="default">
                  <path d="m54.4 44.1v-7.6h.9l2.6 3.8 2.6-3.8h.9v7.6h-.8v-6.2l-2.7 3.9-2.7-3.9v6.2h-.8"></path>
                  <path d="m62.7 41.3c0 0 0 0 0 0 0-2 .8-3 2.5-3 .3 0 .7.1.9.2.3.1.5.2.7.4.2.1.3.3.4.6s.2.4.3.6c.1.2.1.4.1.6 0 .2 0 .4 0 .4v.2.4h-4.1c.1.6.2 1 .5 1.2.3.3.7.4 1.3.4.8 0 1.5-.1 1.9-.4v.8c-.5.3-1.2.4-2 .4-.3 0-.6 0-.9-.1-.3-.1-.5-.2-.8-.4-.3-.2-.5-.5-.6-.9-.1-.4-.2-.8-.2-1.4m.8-.3h3.3c0-.1 0-.3 0-.4s-.1-.4-.2-.6-.3-.5-.5-.6c-.2-.2-.6-.2-.9-.2-.6 0-1 .1-1.2.4s-.5.7-.5 1.4"></path>
                  <path d="m68.9 44.1v-7.8h.8v2.4c.6-.2 1.1-.4 1.7-.4.7 0 1.2.2 1.6.6.4.4.6 1 .6 1.8v3.4h-.8v-3.4c0-1.1-.5-1.6-1.4-1.6-.5 0-1.1.1-1.6.3v4.6h-.9z"></path>
                  <path d="m74.8 44.1v-5.2c.9-.4 1.8-.5 2.7-.5v.8c-.6 0-1.3.1-1.9.3v4.7h-.8z"></path>
                  <path d="m81.4 44.1v-.7l4.3-6h-4.2v-.8h5.3v.7l-4.3 6.1h4.3v.8h-5.4z"></path>
                  <path d="m87.9 41.3c0 0 0 0 0 0 0-2 .8-3 2.5-3 .3 0 .7.1.9.2.3.1.5.2.7.4.2.1.3.3.4.6.1.2.2.4.3.6.1.2.1.4.1.6 0 .2 0 .4 0 .4v.2.4h-4.1c.1.6.2 1 .5 1.2.3.3.7.4 1.3.4.8 0 1.5-.1 1.9-.4v.8c-.5.3-1.2.4-2 .4-.3 0-.6 0-.9-.1-.3-.1-.5-.2-.8-.4-.3-.2-.5-.5-.6-.9-.1-.4-.2-.8-.2-1.4m.8-.3h3.3c0-.1 0-.3 0-.4 0-.2-.1-.4-.2-.6-.1-.2-.3-.5-.5-.6-.2-.2-.6-.2-.9-.2-.6 0-1 .1-1.2.4s-.5.7-.5 1.4"></path>
                  <path d="m94.1 37.4v-.9h.9v.9h-.9m0 6.7h.8v-5.7h-.8v5.7"></path>
                  <path d="m95.8 39.1v-.7h1v-1.6h.8v1.6h1.5v.7h-1.5v3.5c0 .3.1.5.2.6.1.1.3.2.6.2.2 0 .4 0 .6-.1v.7c-.2.1-.5.1-.7.1-.2 0-.3 0-.4-.1-.1 0-.3-.1-.5-.2-.2-.1-.3-.3-.4-.5-.1-.2-.2-.5-.2-.9v-3.4h-1z"></path>
                  <path d="m102.8 39.1v-.7h1v-.3c0-.4.1-.7.2-1 .1-.3.2-.5.3-.6.1-.1.3-.2.5-.3.2-.1.4-.1.5-.1.1 0 .2 0 .4 0 .2 0 .4 0 .5.1v.7c-.2 0-.3 0-.4 0-.2 0-.3 0-.4 0-.1 0-.2.1-.3.1-.1.1-.2.2-.3.4-.1.2-.1.4-.1.8v.3h1.6v.7h-1.6v4.9h-.8v-4.9h-1.1z"></path>
                  <path d="m106.6 41.7v-3.3h.8v3.3c0 .6.1 1 .4 1.3.2.3.6.4 1.1.4.5 0 .9-.1 1.1-.4.2-.3.4-.7.4-1.3v-3.3h.8v3.3c0 .8-.2 1.4-.6 1.8-.4.4-1 .6-1.7.6s-1.3-.2-1.7-.6c-.4-.4-.6-1-.6-1.8m1.1-4v-.9h.8v.9h-.8m1.6 0v-.9h.8v.9h-.8"></path>
                  <path d="m112.8 44.1v-5.2c.9-.4 1.8-.5 2.7-.5v.8c-.6 0-1.3.1-1.9.3v4.7h-.8z"></path>
                  <path d="m119.4 41.1c0-.6.1-1 .2-1.4.2-.4.4-.7.6-.9.3-.2.5-.3.8-.4.3-.1.6-.1.9-.1.5 0 1 .1 1.4.2v-2.3h.8v6.1c0 .2 0 .3-.1.5 0 .2-.1.3-.3.6-.1.2-.4.4-.7.5-.3.1-.8.2-1.3.2-.2 0-.4 0-.6-.1-.2 0-.4-.1-.7-.2-.2-.1-.4-.3-.6-.5-.2-.2-.3-.5-.4-.9.1-.3 0-.8 0-1.3m.8 0c0 .5.1.9.2 1.2.1.3.2.6.4.7.2.1.4.2.5.3.2 0 .4.1.6.1 1 0 1.5-.3 1.5-1v-3.1c-.5-.2-.9-.3-1.4-.3-.6 0-1.1.2-1.3.5-.3.4-.5.9-.5 1.6"></path>
                  <path d="m125.2 42.3c0-.3 0-.5.1-.7s.2-.4.3-.5c.1-.1.3-.2.5-.3.2-.1.4-.2.6-.2.2 0 .4-.1.6-.1.2 0 .4 0 .5 0 .1 0 .2 0 .3 0l.9.1c0-.5-.1-.9-.4-1.1-.3-.2-.7-.3-1.2-.3-.6 0-1.2.1-1.7.3v-.8c.6-.2 1.1-.3 1.7-.3.8 0 1.4.2 1.8.6.4.4.6 1 .6 1.8v1.7c0 .4-.1.7-.3.9s-.4.4-.6.6c-.3.1-.5.2-.7.2-.2 0-.5.1-.7.1-.2 0-.4 0-.5 0-.2 0-.4-.1-.6-.2-.2-.1-.4-.2-.6-.3-.2-.1-.3-.3-.4-.5-.1-.4-.2-.7-.2-1m.9 0c0 .7.5 1.1 1.5 1.1 1 0 1.5-.3 1.5-1v-1.1c-.3 0-.6-.1-1-.1-1.4 0-2 .4-2 1.1"></path>
                  <path d="m130.8 39.9c0-.5.2-.8.5-1.2.3-.3.8-.5 1.5-.5.5 0 1 .1 1.5.3v.8c-.4-.3-.9-.4-1.4-.4-.9 0-1.3.3-1.3.9 0 .2.1.4.3.5.2.1.5.2.8.3.3.1.6.2.9.3.3.1.6.3.8.6.2.3.3.6.3 1 0 .5-.2.9-.6 1.2-.4.3-.9.4-1.5.4-.6 0-1.2-.1-1.7-.4v-.9c.5.3 1.1.5 1.7.5.9 0 1.3-.3 1.3-.8 0-.3-.1-.5-.3-.7-.2-.2-.5-.3-.8-.4-.3-.1-.6-.2-.9-.3-.3-.1-.6-.3-.8-.5-.2 0-.3-.3-.3-.7"></path>
                  <path d="m138.1 36.5h.8l2 6.4 2-6.4h.8l2 6.3 2-6.3h.8l-2.4 7.6h-.8l-2-6.2-2 6.2h-.8l-2.4-7.6"></path>
                  <path d="m149 41.3c0 0 0 0 0 0 0-2 .8-3 2.5-3 .3 0 .7.1.9.2.3.1.5.2.7.4.2.1.3.3.4.6.1.2.2.4.3.6s.1.4.1.6c0 .2 0 .4 0 .4v.2.4h-4.1c.1.6.2 1 .5 1.2.3.3.7.4 1.3.4.8 0 1.5-.1 1.9-.4v.8c-.5.3-1.2.4-2 .4-.3 0-.6 0-.9-.1s-.5-.2-.8-.4-.5-.5-.6-.9c-.1-.4-.2-.8-.2-1.4m.8-.3h3.3c0-.1 0-.3 0-.4 0-.2-.1-.4-.2-.6-.1-.2-.3-.5-.5-.6-.2-.2-.5-.2-.9-.2-.6 0-1 .1-1.2.4s-.4.7-.5 1.4"></path>
                  <path d="m154.7 39.9c0-.5.2-.8.5-1.2.3-.3.8-.5 1.5-.5.5 0 1 .1 1.5.3v.8c-.4-.3-.9-.4-1.4-.4-.9 0-1.3.3-1.3.9 0 .2.1.4.3.5.2.1.5.2.8.3.3.1.6.2.9.3.3.1.6.3.8.6.2.3.3.6.3 1 0 .5-.2.9-.6 1.2-.4.3-.9.4-1.5.4-.6 0-1.2-.1-1.7-.4v-.9c.5.3 1.1.5 1.7.5.9 0 1.3-.3 1.3-.8 0-.3-.1-.5-.3-.7s-.5-.3-.8-.4c-.3-.1-.6-.2-.9-.3-.3-.1-.6-.3-.8-.5-.2 0-.3-.3-.3-.7"></path>
                  <path d="m159.2 41.3c0 0 0 0 0 0 0-2 .8-3 2.5-3 .3 0 .7.1.9.2.3.1.5.2.7.4.2.1.3.3.4.6.1.2.2.4.3.6.1.2.1.4.1.6 0 .2 0 .4 0 .4v.2.4h-4.1c.1.6.2 1 .5 1.2.3.3.7.4 1.3.4.8 0 1.5-.1 1.9-.4v.8c-.5.3-1.2.4-2 .4-.3 0-.6 0-.9-.1s-.5-.2-.8-.4-.5-.5-.6-.9c-.1-.4-.2-.8-.2-1.4m.8-.3h3.3c0-.1 0-.3 0-.4 0-.2-.1-.4-.2-.6-.1-.2-.3-.5-.5-.6-.2-.2-.6-.2-.9-.2-.6 0-1 .1-1.2.4s-.4.7-.5 1.4"></path>
                  <path d="m165.3 44.1v-5.2c.7-.4 1.5-.6 2.4-.6.7 0 1.3.2 1.7.5.4.4.6.9.6 1.5v3.7h-.8v-3.7c0-.2 0-.3 0-.4s-.1-.3-.2-.4c-.1-.1-.2-.2-.5-.3-.2-.1-.5-.1-.8-.1-.5 0-1 .1-1.5.2v4.7h-.9z"></path>
                  <path d="m170.7 39.1v-.7h1v-1.6h.8v1.6h1.5v.7h-1.5v3.5c0 .3.1.5.2.6.1.1.3.2.6.2.2 0 .4 0 .6-.1v.7c-.2.1-.5.1-.7.1-.2 0-.3 0-.4-.1-.1 0-.3-.1-.5-.2-.2-.1-.3-.3-.4-.5-.1-.2-.2-.5-.2-.9v-3.4h-1z"></path>
                  <path d="m175.1 44.1v-7.8h.8v7.8h-.8"></path>
                  <path d="m177.6 37.4v-.9h.9v.9h-.9m0 6.7h.8v-5.7h-.8v5.7"></path>
                  <path d="m179.7 41.2c0-.6.1-1.1.2-1.5.2-.4.4-.7.6-.9.3-.2.6-.3.8-.4.3-.1.6-.1.9-.1.6 0 1.1.1 1.7.3v.8c-.5-.2-1-.3-1.6-.3-.7 0-1.2.2-1.5.5s-.5.9-.5 1.6c0 .8.2 1.3.5 1.7.3.3.8.5 1.5.5.6 0 1.1-.1 1.6-.3v.8c-.5.2-1.1.3-1.7.3-.3 0-.5 0-.7-.1-.2 0-.5-.1-.7-.3-.2-.1-.4-.3-.6-.5-.2-.2-.3-.5-.4-.8 0-.4-.1-.8-.1-1.3"></path>
                  <path d="m185 44.1v-7.8h.8v2.4c.6-.2 1.1-.4 1.7-.4.7 0 1.2.2 1.6.6.4.4.6 1 .6 1.8v3.4h-.8v-3.4c0-1.1-.5-1.6-1.4-1.6-.5 0-1.1.1-1.6.3v4.6h-.9z"></path>
                  <path d="m190.8 41.3c0 0 0 0 0 0 0-2 .8-3 2.5-3 .3 0 .7.1.9.2.3.1.5.2.7.4.2.1.3.3.4.6.1.2.2.4.3.6.1.2.1.4.1.6 0 .2 0 .4 0 .4v.2.4h-4.1c.1.6.2 1 .5 1.2.3.3.7.4 1.3.4.8 0 1.5-.1 1.9-.4v.8c-.5.3-1.2.4-2 .4-.3 0-.6 0-.9-.1-.3-.1-.5-.2-.8-.4-.3-.2-.5-.5-.6-.9-.2-.4-.2-.8-.2-1.4m.8-.3h3.3c0-.1 0-.3 0-.4 0-.2-.1-.4-.2-.6-.1-.2-.3-.5-.5-.6-.2-.2-.5-.2-.9-.2-.6 0-1 .1-1.2.4-.3.2-.5.7-.5 1.4"></path>
                  <path d="m197.3 44.1v-.9h.8v.9h-.8"></path>
                </g>
              </svg>
            </a>
          </div>

          <div className="footer-navigation">
            <ButtonTwo
                buttonTitle={this.props.previousSubpage.title}
                linkTo={'/article/' + this.props.previousSubpage.title}
                iconPosition={'before'}            //after is default
                direction={'left'}                 // value required
                buttonStyle={''}
            />

            <ButtonTwo
                linkTo={"/home"}
                iconPosition={'before'}
                direction={'left'}
                buttonStyle={''}
                iconName={'home'}
            />

            <ButtonTwo
                buttonTitle={this.props.nextSubpage.title}
                linkTo={'/article/' + this.props.nextSubpage.title}
                iconPosition={''}
                direction={'right'}
                buttonStyle={''}
            />
          </div>


          <ImageLink linkTo={'/article/' + this.props.nextSubpage.title}>
            <div className="footer-next-category" style={{backgroundColor: `${this.props.nextSubpage.dataColor}`}}>
              <div className={`article-information ${this.props.nextSubpage.cat}`}>
                <div className="big-headline-wrapper">
                  <h1 className="big-headline" dangerouslySetInnerHTML={{__html: this.props.nextSubpage.claim}}></h1>
                </div>
              </div>

              <div className="article-image">
                <div className="img-holder"
                     style={{backgroundImage: `url(${this.props.nextSubpage.articleImage})`}}></div>
              </div>
            </div>
          </ImageLink>
        </div>
    );
  }
}
