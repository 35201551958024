class AnimationState {
  constructor() {
    this._isAnimating = false;
    this._isPaused = false;
    this._isStopped = false;
  }

  get isAnimating() {
    return this._isAnimating;
  }

  set isAnimating(isAnimating) {
    this._isAnimating = isAnimating;
  }

  get isPaused() {
    return this._isPaused;
  }

  set isPaused(isPaused) {
    this._isPaused = isPaused;
  }

  get isStopped() {
    return this._isStopped;
  }

  set isStopped(isStopped) {
    this._isStopped = isStopped;
  }
}

export default new AnimationState();
