import React, { Component } from "react";
import { withRouter } from "react-router";
import iconHome from "../assets/img/home.svg";
import iconArrow from "../assets/img/arrow.svg";
import { withRoutingContextConsumer } from "../global/Contexts";

class ButtonTwo extends Component  {
  static defaultProps = {
    linkTo: '',
    icon:'',
    iconPosition: '',
    direction: '',
    buttonStyle: ''
  };

  handleClick(event) {
    // event.stopPropagation();

    const { history, linkTo} = this.props;
    const { changeToPageAnimated } = this.props.routingContext;
    changeToPageAnimated(history, linkTo);

    /*changePageTransition('show');
    setTimeout(() => window.scrollTo(0,0), 350);
    setTimeout(() => {history.push(linkTo); changePageTransition('')}, 870);*/
  }

  render() {
    let { iconPosition, buttonStyle, buttonTitle, direction, classNames} = this.props;

    if(this.props.iconName === 'home') {
      return (
          <a className={`${buttonStyle === 'onlyArrow' ? 'ci-button-only-arrow' : 'ci-button'} icon-${iconPosition} button-home`}
             onClick={(event) => this.handleClick(event)}>
            <div className={"icon-border"}>
              <img src={iconHome} alt={"icon"} className={"icon-home"} />
            </div>
          </a>
      );
    } else if(this.props.iconName === 'none') {
      return (
          <a className={`ci-button ${classNames}`} onClick={(event) => this.handleClick(event)}>
            <span className={"ci-link-text"}>{buttonTitle}</span>
          </a>
      );

    } else {
      return (
          <a className={`${buttonStyle === 'onlyArrow' ? 'ci-button-only-arrow' : 'ci-button'} icon-${iconPosition}`} onClick={(event) => this.handleClick(event)}>

            <span className={"ci-link-text"}>{buttonTitle}</span>
            <div className={"icon-border"}>
              <img src={iconArrow} alt={"icon"} className={`icon-arrow ${direction}`} />
            </div>
          </a>
      );
    }
  }
}

export default withRoutingContextConsumer(withRouter(ButtonTwo));
