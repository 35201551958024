import React, { Component } from 'react';
import { withRouter } from "react-router";
import data from "./assets/data/data";

import ImageModule from "./components/ImageModule";
import TextMediaModule from "./components/TextMediaModule";
import TextModule from "./components/TextModule";
import Teaser from "./components/Teaser";
import ContactForm from "./components/ContactForm";
import Button from "./components/Button";
import { withAppContextConsumer } from "./global/Contexts";

class AboutUs extends Component  {
  componentDidMount() {
    const {changeTitle, changeMainClass} = this.props.appContext;

    changeMainClass('is-subpage about-us-page');
    changeTitle("Der Tag mit Euronet - Über uns");
  }

  componentWillUnmount() {
    this.props.appContext.changeMainClass('');
  }

  render() {
    const aboutUs = data.aboutUs;

    return (
      <div className={"category__content"}>
        <section className="category__content--section" key={aboutUs.id}>
          <div className={"additional-information"}>
            <h1 className={"headline-subpage"}>{aboutUs.title}</h1>
            <div className={"subpage-description"}>
              <p>{aboutUs.description}</p>
            </div>
          </div>

          <div className={"headline-claim-subpage"}>
            <div className={"intro"}>
              <div className={"claim"}>{aboutUs.claimSubpage}</div>
              <h3>{aboutUs.subtitle}</h3>
            </div>
            <Button
                buttonTitle={"Zur Übersicht"}
                linkTo={"/home"}
                iconPosition={'before'}
                direction={'left'}
                buttonStyle={''}
            />
          </div>
        </section>

        <section className={"section-wrapper"}>
          {aboutUs.modules.map((module, i) => {
            if(module.type === 'imageModule') {
              return <ImageModule
                  key={i}
                  srcImage={module.src}
                  classNames={module.classNames}
                  imageTitle={module.imageTitle}

              />
            }

            if (module.type === 'textMediaModules') {
              return <TextMediaModule
                  key={i}
                  title={module.textMediaTitle}
                  positionImage={module.positionImage}
                  imageSize={module.imageSize}
                  srcImage={module.textMediaImage.src}
                  imageTitle={module.textMediaImage.imageTitle}
                  description={module.textMediaDescription}
              />
            }

            if (module.type === 'textModules') {
              return <TextModule
                  key={i}
                  title={module.textModuleTitle}
                  description={module.textDescription}
                  textWidth={module.textWidth}
              />
            }

            if(module.type === 'teaserList') {
              return (
                  <div className={"teaser-list"}>
                    <Teaser
                        key={i}
                        title={module.teaserTitle}
                        srcImage={module.teaserImage.srcImage}
                        titleImage={module.teaserImage.imageTitle}
                        description={module.teaserText}
                    />
                  </div>
              );
            }
          })}

          <ContactForm />
        </section>

      </div>
    );
  }
}

export default withAppContextConsumer(withRouter(AboutUs));
