import React, { Component } from 'react';
import { BrowserRouter } from "react-router-dom";
import clsx from "clsx";
import './App.scss';
import Routing from "./frontend/Routing";
import { AppContext } from './frontend/global/Contexts';
import data from "./frontend/assets/data/data";
import $ from "jquery";
import {gsap} from "gsap"

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      imagePositions: [],
      isPreloading: true
    };
  }

  componentDidMount() {
    //preload subpage images
    data.subpages.forEach((subpage) => {
      const img = new Image();
      img.src = subpage.articleImage;
    });

    //fake preloader
    setTimeout(() => {
      this.setState({
        ...this.state,
        isPreloading: false
      });
    }, 2500);

    const preloaderSvg = $('.preloader-container svg'),
        tl = gsap.timeline();

    tl
        .set(preloaderSvg, {scale: 0.1, autoAlpha: 0})
        .to(preloaderSvg, {rotation: "720deg", duration: 2.5, ease: "linear"})
        .to(preloaderSvg, {scale: 1, autoAlpha: 1, ease: "back.out(1.7)", duration: 0.5}, "<")
        .to(preloaderSvg, {scale: 0, autoAlpha: 0, ease: "back.in(1.7)", duration: 0.5}, "<+2")
    ;
  }

  changeMainClass(classNamesSubpage) {
    this.setState({
      ...this.state,
      classNamesSubpage: classNamesSubpage
    });
  }

  changeTitle(newTitle) {
    document.title = newTitle;
    document.querySelector('meta[property="og:title"]').setAttribute("content", newTitle);
  }

  render() {
    const { isPreloading } = this.state;

    return <>
      {isPreloading &&
      <div className={"preloader-container"}>
        <svg xmlns="http://www.w3.org/2000/svg" y="0px" viewBox="0 0 43.6 43.7">
          <g>
            <path className={"st0"} d="M37.3,6.4c3.4,3.5,5,8.5,4.1,13.3c-1,5.4-5,10-10.2,11.6c-5.5,1.8-11.7,0.1-15.6-4.1c-2.1-2.3-3.5-5.2-3.9-8.2
              c-3.2,4.1-4,9.7-2.2,14.6c1.6,4.3,5.2,7.6,9.6,9c2.7,0.9,5.7,0.9,8.4,0.2C32,41.6,36,39,38.9,35.3c2.8-3.6,4.5-8,4.7-12.5
              c0.1-2.3-0.1-4.5-0.7-6.7C41.9,12.3,40,9.1,37.3,6.4"/>
            <path className={"st1"} d="M0.8,16C2,11.4,5.5,7.6,9.9,5.9C15,4,20.9,5,25,8.5c2.3,2,4,4.6,4.8,7.5s0.7,5.9-0.2,8.8
              c-0.9,2.8-2.7,5.3-5.1,7.1c5.6,0.8,11.2-1.7,14.4-6.4c2.6-3.8,3.4-8.6,2-13c-1.4-4.6-5-8-9.2-10.2c-4-2-8.7-2.8-13.3-2.1
              c-4.5,0.7-8.8,2.9-12,6.1C3.6,9.1,1.8,12.4,0.8,16"/>
            <path className={"st2"} d="M27.4,42.9c-4.5,1.2-9.5,0.2-13.2-2.8c-4.2-3.3-6.4-8.7-5.6-14c0.9-5.8,5.1-10.7,10.8-12.3
              c3.2-0.9,6.8-0.7,9.9,0.5C27.3,9.5,23,6,17.9,5.2C13.4,4.4,8.6,5.8,5.2,8.9c-1.7,1.5-3.1,3.5-3.9,5.6c-0.9,2.2-1.2,4.6-1.3,7
              c-0.1,4.6,1.3,9.1,4,12.9s6.6,6.7,11,8.1c0.4,0.1,0.8,0.2,1.2,0.4C20,43.9,23.7,43.8,27.4,42.9"/>
          </g>
        </svg>
      </div>
      }
      {!isPreloading && <AppContext.Provider value={{
        changeMainClass: (classNamesSubpage) => this.changeMainClass(classNamesSubpage),
        changeTitle: (newTitle) => this.changeTitle(newTitle)
      }}>
        <div className={clsx('app-euronet', this.state.classNamesSubpage)}>
          <BrowserRouter>
            <Routing/>
          </BrowserRouter>
        </div>
      </AppContext.Provider>}
    </>;
  }
}

export default App;
