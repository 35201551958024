import React, {Component} from 'react';

export default class ImageModule extends Component {
  static defaultProps = {
    imageTitle: '',
    srcImage:'',
    classNames:''
  };

  render() {
    let {srcImage, classNames, imageTitle} = this.props;

    return (
        <div className={`image-module ${classNames}`}>
          <img src={srcImage} alt={imageTitle}/>
        </div>
    );
  }
}
