import React, { Component } from 'react';
import {withRouter} from "react-router";
import ButtonTwo from "./ButtonTwo";
import ImageLink from "./ImageLink";
//import $ from "jquery";

class Header extends Component {
  static defaultProps = {
    linkTo: ''
  };

  render() {
    if (this.props.location.pathname === "/home") {
      return (
          <div className="header">
            <svg className={"euronet-logo"} id="euronet-logo" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 362.91 76.92">
              <defs>
                <filter id="dropshadow" height="130%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
                  <feOffset dx="2" dy="2" result="offsetblur"/>
                  <feComponentTransfer>
                    <feFuncA type="linear" slope="0.2"/>
                  </feComponentTransfer>
                  <feMerge>
                    <feMergeNode/>
                    <feMergeNode in="SourceGraphic"/>
                  </feMerge>
                </filter>
              </defs>
              <g id="symbol" filter="url(#dropshadow)">
                <path
                    d="M26.47,49.37a28.66,28.66,0,0,1-6.91-13.14A26.44,26.44,0,0,0,33.94,75.72a26.39,26.39,0,0,0,14.74.3A38.75,38.75,0,0,0,77.31,40.56,38.49,38.49,0,0,0,76,28.64c-.13-.48-.26-.95-.41-1.42a28.15,28.15,0,0,1-.33,7.92A28.45,28.45,0,0,1,26.47,49.37Z"/>
                <path
                    d="M72.6,22.23c-2.25-7.35-8-13.72-16.22-17.95a38.7,38.7,0,0,0-45.07,7c-.36.36-.71.72-1.05,1.09a29,29,0,0,1,6.46-3.49,28.89,28.89,0,0,1,28.43,4.81,28.43,28.43,0,0,1,1,42.69,26.78,26.78,0,0,0,23-11.36A26.63,26.63,0,0,0,72.6,22.23Z"/>
                <path
                    d="M50.32,23.34a26.72,26.72,0,0,0-19-13.9,25.4,25.4,0,0,0-4.43-.38A26.43,26.43,0,0,0,2.26,25.78,35.55,35.55,0,0,0,0,38.1,38.68,38.68,0,0,0,26.55,75.4c.7.23,1.4.44,2.11.63.49.13,1,.26,1.48.37a28.35,28.35,0,0,1-16.46-30A28.86,28.86,0,0,1,34,23.15,28.46,28.46,0,0,1,50.32,23.34Z"/>
              </g>
              <g id="euronet" filter="url(#dropshadow)">
                <path
                    d="M174.2,18.41V40.54c0,11.92-6.81,19-18.32,19s-18.24-7-18.24-19V18.41h9.81V41.84c0,6.48,2.43,9.16,8.43,9.16s8.51-2.67,8.51-9.16V18.41Z"/>
                <path d="M202.82,17.76v8.43a40,40,0,0,0-10.46,1.38v31.3h-9.73V22.06a54.83,54.83,0,0,1,20.19-4.3"/>
                <path
                    d="M234.41,38.68c0-10.7-3.65-12.81-9.4-12.81s-9.4,2.11-9.4,12.81,3.65,12.81,9.4,12.81,9.4-2.11,9.4-12.81m9.73,0c0,13.46-6.81,20.84-19.13,20.84s-19.13-7.38-19.13-20.84S212.69,17.84,225,17.84s19.13,7.38,19.13,20.84"/>
                <path
                    d="M287,34.55V58.87h-9.65V33.74c0-3.57-.73-7.38-9.57-7.38a42.19,42.19,0,0,0-7.54.73V58.87h-9.73V22.22a42.48,42.48,0,0,1,18.57-4.46C280.38,17.76,287,24,287,34.55"/>
                <path
                    d="M346.18,27.9V46.71c0,3.56,1.38,4.46,4.95,4.46a37.13,37.13,0,0,0,8.48-2l3.3,6.8c-1.94.81-7.74,3.52-13.57,3.52-8,0-12.89-5-12.89-13.38V6.41h9.73V19.54h16.17v8.35H346.18Z"/>
                <path
                    d="M311.47,25.63c5.19,0,7.7,4.86,8.19,10h-16.3c.58-7.21,3.33-10,8.11-10M324.6,49.21a31.28,31.28,0,0,1-10.94,2c-4.7,0-9.73-1.05-10.3-8.83h26c.16-1.62.16-3.24.16-5,0-10.14-5.43-19.62-18.08-19.62-13.21,0-18.08,9.32-18.08,21.4,0,17.19,10.78,20.43,18.81,20.43a31.43,31.43,0,0,0,15.24-3.65v-.14Z"/>
                <path
                    d="M112.41,25.63c5.19,0,7.7,4.86,8.19,10H104.31c.56-7.21,3.32-10,8.1-10m13.13,23.58a31.28,31.28,0,0,1-10.94,2c-4.7,0-9.73-1.05-10.29-8.83h26c.16-1.62.16-3.24.16-5,0-10.14-5.43-19.62-18.08-19.62-13.22,0-18.08,9.32-18.08,21.4,0,17.19,10.78,20.43,18.81,20.43a31.43,31.43,0,0,0,15.24-3.65v-.14Z"/>
              </g>

            </svg>

            <ButtonTwo
                classNames={"about-us"}
                buttonTitle={"Über uns"}
                iconName={"none"}
                linkTo={"/about-us"}
                direction={'right'}
                buttonStyle={''}
            />
          </div>
      );
    } else {
      return (
          <div className="header">
            <ImageLink linkTo={'/home'}>
              <svg className={"euronet-logo"} id="euronet-logo" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 362.91 76.92">

                <g id="symbol">
                  <path
                      d="M26.47,49.37a28.66,28.66,0,0,1-6.91-13.14A26.44,26.44,0,0,0,33.94,75.72a26.39,26.39,0,0,0,14.74.3A38.75,38.75,0,0,0,77.31,40.56,38.49,38.49,0,0,0,76,28.64c-.13-.48-.26-.95-.41-1.42a28.15,28.15,0,0,1-.33,7.92A28.45,28.45,0,0,1,26.47,49.37Z"/>
                  <path
                      d="M72.6,22.23c-2.25-7.35-8-13.72-16.22-17.95a38.7,38.7,0,0,0-45.07,7c-.36.36-.71.72-1.05,1.09a29,29,0,0,1,6.46-3.49,28.89,28.89,0,0,1,28.43,4.81,28.43,28.43,0,0,1,1,42.69,26.78,26.78,0,0,0,23-11.36A26.63,26.63,0,0,0,72.6,22.23Z"/>
                  <path
                      d="M50.32,23.34a26.72,26.72,0,0,0-19-13.9,25.4,25.4,0,0,0-4.43-.38A26.43,26.43,0,0,0,2.26,25.78,35.55,35.55,0,0,0,0,38.1,38.68,38.68,0,0,0,26.55,75.4c.7.23,1.4.44,2.11.63.49.13,1,.26,1.48.37a28.35,28.35,0,0,1-16.46-30A28.86,28.86,0,0,1,34,23.15,28.46,28.46,0,0,1,50.32,23.34Z"/>
                </g>
                <g id="euronet">
                  <path
                      d="M174.2,18.41V40.54c0,11.92-6.81,19-18.32,19s-18.24-7-18.24-19V18.41h9.81V41.84c0,6.48,2.43,9.16,8.43,9.16s8.51-2.67,8.51-9.16V18.41Z"/>
                  <path d="M202.82,17.76v8.43a40,40,0,0,0-10.46,1.38v31.3h-9.73V22.06a54.83,54.83,0,0,1,20.19-4.3"/>
                  <path
                      d="M234.41,38.68c0-10.7-3.65-12.81-9.4-12.81s-9.4,2.11-9.4,12.81,3.65,12.81,9.4,12.81,9.4-2.11,9.4-12.81m9.73,0c0,13.46-6.81,20.84-19.13,20.84s-19.13-7.38-19.13-20.84S212.69,17.84,225,17.84s19.13,7.38,19.13,20.84"/>
                  <path
                      d="M287,34.55V58.87h-9.65V33.74c0-3.57-.73-7.38-9.57-7.38a42.19,42.19,0,0,0-7.54.73V58.87h-9.73V22.22a42.48,42.48,0,0,1,18.57-4.46C280.38,17.76,287,24,287,34.55"/>
                  <path
                      d="M346.18,27.9V46.71c0,3.56,1.38,4.46,4.95,4.46a37.13,37.13,0,0,0,8.48-2l3.3,6.8c-1.94.81-7.74,3.52-13.57,3.52-8,0-12.89-5-12.89-13.38V6.41h9.73V19.54h16.17v8.35H346.18Z"/>
                  <path
                      d="M311.47,25.63c5.19,0,7.7,4.86,8.19,10h-16.3c.58-7.21,3.33-10,8.11-10M324.6,49.21a31.28,31.28,0,0,1-10.94,2c-4.7,0-9.73-1.05-10.3-8.83h26c.16-1.62.16-3.24.16-5,0-10.14-5.43-19.62-18.08-19.62-13.21,0-18.08,9.32-18.08,21.4,0,17.19,10.78,20.43,18.81,20.43a31.43,31.43,0,0,0,15.24-3.65v-.14Z"/>
                  <path
                      d="M112.41,25.63c5.19,0,7.7,4.86,8.19,10H104.31c.56-7.21,3.32-10,8.1-10m13.13,23.58a31.28,31.28,0,0,1-10.94,2c-4.7,0-9.73-1.05-10.29-8.83h26c.16-1.62.16-3.24.16-5,0-10.14-5.43-19.62-18.08-19.62-13.22,0-18.08,9.32-18.08,21.4,0,17.19,10.78,20.43,18.81,20.43a31.43,31.43,0,0,0,15.24-3.65v-.14Z"/>
                </g>
              </svg>
            </ImageLink>

            <ButtonTwo
                classNames={"about-us"}
                buttonTitle={"Über uns"}
                iconName={"none"}
                linkTo={"/about-us"}
                direction={'right'}
                buttonStyle={''}
            />
          </div>
      );
    }
  }
}

export default withRouter(Header);
