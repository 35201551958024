import React, {Component} from 'react';
import data from "../assets/data/data";

export default class BulletpointModule extends Component {
  constructor(props) {
    super(props);
    this.state = {addClass: false};
  }

  static defaultProps = {
    title: '',
    bulletList: '',
    classNames: '',
    textWidth: ''
  };

  render() {
    let {textWidth, title, bulletpointList} = this.props;

    return (
        <div className={`text-module${textWidth === 'half' ? ' half-width' : ' full-width'}`}>
          <h4 className="text-media-headline">{title}</h4>
          <div className={`text-media-container`}>

            <div className={"text-description"}>
              <ul>
                {bulletpointList.map((item, i) => {
                  return <li className="list-item" key={i}>{item}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
    );
  }
}
