import React, { Component } from 'react';
import { withRouter } from "react-router";
import data from "./assets/data/data";

import ImageModule from "./components/ImageModule";
import TextMediaModule from "./components/TextMediaModule";
import TextModule from "./components/TextModule";
import Teaser from "./components/Teaser";
import Button from "./components/Button";
import { withAppContextConsumer } from "./global/Contexts";

class Impressum extends Component  {
  componentDidMount() {
    const {changeTitle, changeMainClass} = this.props.appContext;

    changeTitle("Der Tag mit Euronet - Impressum");
    changeMainClass('is-subpage');
  }

  componentWillUnmount() {
    this.props.appContext.changeMainClass('');
  }

  render() {
    const impressum = data.impressum;

    return (
      <div className={"category__content"}>
        <section className="category__content--section" key={impressum.id}>
          <div className={"additional-information"}>
            <h1 className={"headline-subpage"}>{impressum.title}</h1>
            <div className={"subpage-description"}>
              <p>{impressum.description}</p>
            </div>
          </div>

          <div className={"headline-claim-subpage"}>
            <div className={"claim"}>{impressum.claim}</div>
            <h3>{impressum.subtitle}</h3>
            <Button
                buttonTitle={"Zur Übersicht"}
                linkTo={"/home"}
                iconPosition={'before'}
                direction={'left'}
                buttonStyle={''}
            />
          </div>
        </section>

        <section className={"section-wrapper impressum"}>
          {impressum.modules.map((module) => {
            if(module.type === 'imageModule') {
              return <ImageModule srcImage={module.src} classNames={module.classNames} />
            }

            if (module.type === 'textMediaModules') {
              return <TextMediaModule
                  title={module.textMediaTitle}
                  positionImage={module.positionImage}
                  imageSize={module.imageSize}
                  srcImage={module.textMediaImage.src}
                  imageTitle={module.textMediaImage.imageTitle}
                  description={module.textMediaDescription}
              />
            }

            if (module.type === 'textModules') {
              return <TextModule
                  title={module.textModuleTitle}
                  description={module.textDescription}
                  textWidth={module.textWidth}
              />
            }

            if(module.type === 'teaserList') {
              return (
                  <div className={"teaser-list"}>
                    <Teaser
                        title={module.teaserTitle}
                        srcImage={module.teaserImage.srcImage}
                        titleImage={module.teaserImage.imageTitle}
                        description={module.teaserText}
                    />
                  </div>
              );
            }
          })}

        </section>
      </div>
    );
  }
}

export default withAppContextConsumer(withRouter(Impressum));
