import React, { Component } from "react";
import { withRouter } from "react-router";
import AnimationState from "../global/AnimationState";
import { withRoutingContextConsumer } from "../global/Contexts";

class Button extends Component  {
  static defaultProps = {
    linkTo: '',
    iconPosition: '',
    direction: '',
    buttonStyle: '',
    classNames:''
  };

  handleClick() {
    const { history, linkTo} = this.props;
    const { changeToPageAnimated } = this.props.routingContext;
    changeToPageAnimated(history, linkTo);
  }

  onMouseEnterHandler() {
    AnimationState.isPaused = true;
  }

  onMouseLeaveHandler() {
    AnimationState.isPaused = false;
  }

  render() {
    let { iconPosition, buttonStyle, buttonTitle, direction, classNames} = this.props;

    return (
        <a href="#"
           onMouseEnter={() => this.onMouseEnterHandler()}
           onMouseLeave={() => this.onMouseLeaveHandler()}
           className={`${buttonStyle === 'onlyArrow' ? 'ci-button-only-arrow' : 'ci-button'} icon-${iconPosition} ${classNames}`} onClick={(event) => this.handleClick(event)}>
          <span className={"ci-link-text"}>{buttonTitle}</span>
          <div className={"icon"}>
            <span className={`arrow ${direction}`} />
          </div>
        </a>
    );
  }
}

export default withRoutingContextConsumer(withRouter(Button));
