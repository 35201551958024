import React, { Component } from 'react';
import { gsap, DrawSVGPlugin } from "gsap/all";

import Clock from './Clock';
import AnimationState from "../global/AnimationState";

gsap.registerPlugin(DrawSVGPlugin);

export default class ArticleContainer extends Component {
  static articleContainerState = null;

  constructor(props) {
    super(props);
    this.state = {
      rotation: ArticleContainer.articleContainerState ? ArticleContainer.articleContainerState.rotation : 0,
      currentTimepoint: ArticleContainer.articleContainerState ? ArticleContainer.articleContainerState.currentTimepoint : null,
      timePoints: [],
      clockRotations: ArticleContainer.articleContainerState ? ArticleContainer.articleContainerState.clockRotations : 0,
      imagePositions: ArticleContainer.articleContainerState?  ArticleContainer.articleContainerState.imagePositions : [],
      previousTimepoint: null
    };
  }

  componentDidMount() {
    this.calculateTimePoints();
    this.interval = setInterval(this.timer.bind(this), 180);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  persistState() {
    ArticleContainer.articleContainerState = { ...this.state };
  }

  calculateTimePoints() {
    const { subpages } = this.props;
    const { currentTimepoint } = this.state;

    const timePoints = [];
    let imagePositions = [];

    const partRotation = 360.0 / subpages.length;

    subpages.forEach((subpage, i) => {
      timePoints.push({
        index: i,
        rotation: partRotation * i,
        time: subpage.dataTime,
        color: subpage.dataColor,
        nextIndex: i >= subpages.length - 1 ? 0 : i + 1,
      });

      imagePositions.push(i % 2 === 0 ? 'right' : 'left');
    });

    imagePositions = this.state.imagePositions.length > 0 ? this.state.imagePositions : imagePositions;

    if (currentTimepoint !== null) {
      const nextTimepointIndex = currentTimepoint.index + 1 >= timePoints.length ? 0 : currentTimepoint.index + 1;
      this.props.initFirstTimePoint(currentTimepoint, timePoints[nextTimepointIndex], imagePositions);
    } else {
      this.props.initFirstTimePoint(timePoints[0], timePoints[1], imagePositions);
    }

    const newCurrentTimepoint = currentTimepoint !== null ? currentTimepoint : timePoints[0];

    this.setState({
      ...this.state,
      currentTimepoint: newCurrentTimepoint,
      timePoints: timePoints,
      imagePositions: imagePositions,
    });
  }

  timer() {
    if (AnimationState.isPaused || AnimationState.isStopped) {
      return;
    }

    const { rotation, clockRotations } = this.state;

    let newRotation = rotation + 1;
    if (newRotation >= 360) {
      this.setState({
        ...this.state,
        clockRotations: clockRotations + 1,
      });
      newRotation -= 360;
    }

    this.persistState();
    this.updateRotation(newRotation);
  }

  updateRotation(newRotation) {
    const imagePositions = this.state.imagePositions;
    const previousTimepoint = this.state.currentTimepoint;
    const { handleNewTimepoint } = this.props;

    const currentTimepoint = this.getCurrentTimepoint(newRotation);

    if (previousTimepoint.index !== currentTimepoint.index) {
      //new timepoint reached inform parent
      imagePositions[currentTimepoint.index] = imagePositions[previousTimepoint.index] === 'left' ? 'right' : 'left';
      handleNewTimepoint(currentTimepoint, this.state.currentTimepoint, imagePositions);
    }

    this.setState({
      ...this.state,
      rotation: newRotation,
      currentTimepoint: currentTimepoint,
      imagePositions: imagePositions,
      previousTimepoint: previousTimepoint
    });
  }

  getCurrentTimepoint(rotation) {
    const { timePoints, currentTimepoint } = this.state;

    let newCurrentTimepoint = timePoints[0];
    for (const timePoint of timePoints) {
      if (rotation < timePoint.rotation) {
        break;
      }
      newCurrentTimepoint = timePoint;
    }

    if (newCurrentTimepoint.index === currentTimepoint.index) {
      return currentTimepoint;
    }

    return newCurrentTimepoint;
  }

  render() {
    const {rotation, timePoints, currentTimepoint} = this.state;
    const { subpages } = this.props;

    return (
      <>
        <Clock rotation={rotation}
               timePoints={timePoints}
               currentTimepoint={currentTimepoint}
               updateRotation={(newRotation) => this.updateRotation(newRotation)}
               subpages={subpages}
        />
        {this.props.children}
      </>
    );
  }
}
