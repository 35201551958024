import React, { Component } from 'react';
import $ from "jquery";
import {gsap} from "gsap/all";


export default class Time extends Component  {

  componentDidMount() {
    this.updateClockHeight();
    window.addEventListener("resize", () => this.updateClockHeight(this.startIndex, this.endIndex));

    if (this.props.currentTimepoint !== null) {
      this.startTimeAnimation(0, this.props.currentTimepoint.index)
    }

  }

  componentDidUpdate(oldProps) {
    if (this.props.currentTimepoint !== null && oldProps.currentTimepoint !== null && this.props.currentTimepoint.index !== oldProps.currentTimepoint.index) {
      this.startTimeAnimation(oldProps.currentTimepoint.index, this.props.currentTimepoint.index)
    }
  }

  updateClockHeight() {
    const timeList = $('ul.clock-display__digits'),
        timeDigitsHeight = $('ul.clock-display__digits li').outerHeight();

    gsap.set(timeList, {height: timeDigitsHeight});
  }

  startTimeAnimation(startIndex, endIndex) {
    const timeList = $('ul.clock-display__digits'),
        timeDigitsHeight = $('ul.clock-display__digits li').outerHeight();

    this.startIndex = startIndex;
    this.endIndex = endIndex;

    const aniAmount = timeDigitsHeight * (startIndex - endIndex);

    gsap.to(timeList, {duration: 0.8, stagger: {amount: 0.2}, y: `+=${aniAmount}`, ease: "back.out(1.3)"})
  }

  render() {
    const { subpages } = this.props;

    return (<>
      <time className="clock-display">
        <span className="clock-display__hours">
          <ul className="clock-display__digits">
            {subpages.map((subpage, i) => <li key={i}>{subpage.dataTime.substr(0,1)}</li>)}
          </ul>
          <ul className="clock-display__digits">
            {subpages.map((subpage, i) => <li key={i}>{subpage.dataTime.substr(1,1)}</li>)}
          </ul>
        </span>
        <span className="clock-display__spacer"></span>
        <span className="clock-display__minutes">
          <ul className="clock-display__digits">
{subpages.map((subpage, i) => <li key={i}>{subpage.dataTime.substr(3,1)}</li>)}
          </ul>
          <ul className="clock-display__digits">
            {subpages.map((subpage, i) => <li key={i}>{subpage.dataTime.substr(4,1)}</li>)}
          </ul>
  </span>
      </time>
    </>);
  }
}
