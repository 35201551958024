import React, { Component } from 'react';
import Button from "./Button";
import clsx from "clsx";
import ImageLink from "./ImageLink";

export default class Article extends Component  {
  constructor(props) {
    super(props);
    this.state = {addClass: false};
  }

  static defaultProps = {
    cat:'',
    claim: '',
    title: '',
    subtitle: '',
    dataColor: '',
    dataTime: '',
    linkTo: '',
    classNames: '',
    articleImage: '',
    mobileTime: ''
  };

  render() {
    const { isActive, imagePosition } = this.props;
    let {cat, title, subtitle, dataColor, dataTime, mobileTime, articleImage, claim} = this.props;

    function createLineBreaks() {
      return {__html: claim};
    }

    return (
        <div className={clsx('article-wrapper', isActive && 'active', imagePosition === 'left' && 'odd')}>
          <div style={{backgroundColor: dataColor}} className={`article-information ${cat}`}>
            <div className="big-headline-wrapper">
              <h3 className="mobile-datatime">{mobileTime}</h3>
              <h1 className="big-headline" dangerouslySetInnerHTML={createLineBreaks()}></h1>
            </div>

            <div className="additional-information">
              <h3 className="article-headline">{title}</h3>
              <h4 className="article-subheadline">{subtitle}</h4>

              <Button
                  buttonTitle={title}
                  linkTo={'/article/' + title}
                  iconPosition={''}               //after is default
                  direction={'right'}             // value required
                  buttonStyle={'onlyArrow'}       //on Articlepage choose 'onlyArrow' and no iconPosition
                  /*TODO: BEI HOVER AUF BUTTON MUSS DIE ANIMATION AUCH PAUSIEREN*/
                  /* onMouseEnter={() => this.onMouseEnterHandler()}
                  onMouseLeave={() => this.onMouseLeaveHandler()}*/
              />
            </div>
          </div>

          <div className="article-image">
            <ImageLink linkTo={'/article/' + title}>
              <div className="img-holder" style={{backgroundImage: `url(${articleImage})`}}></div>
            </ImageLink>
          </div>
        </div>
    );
  }
}
