import React, { Component } from 'react';
import clsx from "clsx";

export default class Panel extends Component {
  render() {
    const { imagePosition } = this.props;

    return (
      <>
        <div className={clsx('panel panel-1', imagePosition === 'left' && 'active')}/>
        <div className={clsx('panel panel-2', imagePosition === 'right' && 'active')}/>
      </>
    );
  }
}
