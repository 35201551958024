import React, {Component} from 'react';
import ImageModule from "./ImageModule";

export default class Teaser extends Component {
  static defaultProps = {
    title: '',
    titleImage: '',
    srcImage: '',
    description: '',
    moduleWidth: '',
    classNames: '',
  };

  render() {
    let {srcImage, titleImage, title, description, moduleWidth} = this.props;

    return (
        <div className={`teaser-container${moduleWidth === 'half' ? ' half-width' : ' w-33'}`}>
          <ImageModule srcImage={srcImage} titleImage={titleImage} />

          <div className={`teaser-text`}>
            <h4 className="text-media-headline">{title}</h4>

            <div className={"teaser-text-description"}>
              {description}
            </div>
          </div>
        </div>
    );
  }
}
