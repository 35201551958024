import React, { Component } from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import Home from "./Home";
import ArticleSubpage from "./components/ArticleSubpage";
import AboutUs from "./AboutUs";
import Impressum from "./Impressum";
import clsx from "clsx";
import Header from "./components/Header";
import { RoutingContext } from "./global/Contexts";
import ButtonTwo from "./components/ButtonTwo";

import $ from "jquery";
import gsap from "gsap"

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTransitionClass: false
    }
  }

  changePageTransition(pageTransitionClass) {
    this.setState({pageTransitionClass: pageTransitionClass});
  }

  changeToPageAnimated(history, target) {
    const transRect = $('.page-transition'),
        tl = gsap.timeline();

    const callTarget = () => {
      history.push(target);
      window.scrollTo(0, 0);
    };

    tl
        .set(transRect, {left: "100%", width: "100vw", borderRadius: "100%", autoAlpha: "1"})
        .to(transRect, {left: "0%", duration: 0.3, borderRadius: "0%", ease: "Power.In.2"})
        .addLabel('test', ">")
        .call(callTarget, null, "test")
        .to(transRect, {autoAlpha: "0", duration: 0.5, ease: "Power.Out.2"})
    ;
  }

  render(){
    return <RoutingContext.Provider value={{
      changeToPageAnimated: (history, target) => this.changeToPageAnimated(history, target)
    }}>
      <Header/>
      <div className="container">
        <div className={clsx('page-transition', this.state.pageTransitionClass)} />

        <Switch>
          <Redirect exact from="/" to="/home" />
          <Route exact path="/home" component={Home}/>
          <Route path="/article/:title" component={ArticleSubpage}/>
          <Route path="/about-us" component={AboutUs}/>
          <Route path="/impressum" component={Impressum}/>
        </Switch>
      </div>
      <ButtonTwo
          classNames={"btn-impress link-style"}
          buttonTitle={"Impressum & Datenschutz"}
          linkTo={"/impressum"}
          direction={'right'}
          iconName={"none"}
      />
    </RoutingContext.Provider>;
  }
}

export default Routes;
