import React, { Component } from 'react';
import clsx from "clsx";
import $ from "jquery"
import Time from "./Time";
import {gsap, DrawSVGPlugin} from "gsap/all";
import AnimationState from "../global/AnimationState";

gsap.registerPlugin(DrawSVGPlugin);

export default class Clock extends Component {

  handleClick(timePoint) {
    if (AnimationState.isAnimating) {
      return;
    }

    const { updateRotation } = this.props;
    AnimationState.isPaused = true;
    updateRotation(timePoint.rotation);
    setTimeout(() => {AnimationState.isPaused = false;}, 2000);
  }

  onMouseEnterHandler() {
    AnimationState.isPaused = true;
  }

  onMouseLeaveHandler() {
    AnimationState.isPaused = false;
  }

  render() {
    const { rotation, timePoints, currentTimepoint } = this.props;
    const { subpages } = this.props;

    const radius = 263.3;
    const midX = 296.8;
    const midY = 296.8;

    const drawTimepoint = (timePoint, currentTimepointIndex) => {
      const x = midX + radius * Math.sin(Math.PI * 2 * (360 - (timePoint.rotation + 180)) / 360);
      const y = midY + radius * Math.cos(Math.PI * 2 * (360 - (timePoint.rotation + 180)) / 360);

      return <React.Fragment key={'fragmentCircle-' + timePoint.index}>
        <circle key={'clockCircle1-' + timePoint.index} className="timepoint__hover" cx={x} cy={y} r="60" onClick={() => this.handleClick(timePoint)}/>
        <circle key={'clockCircle2-' + timePoint.index} className={clsx('timepoint', currentTimepointIndex === timePoint.index && 'active')} cx={x} cy={y} r="11" onClick={() => this.handleClick(timePoint)}/>
      </React.Fragment>;
    };

    gsap.to($('svg .progress'), {duration: 0.5, drawSVG: `${rotation / 360 * 100}%`, ease: 'Power1.inOut'});

    return (
      <>
        <div className="clock-container">
          <svg className="clock" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 593.6 593.6"
               onMouseEnter={() => this.onMouseEnterHandler()}
               onMouseLeave={() => this.onMouseLeaveHandler()}>
            <defs>
              <filter id="dropshadow" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
                <feOffset dx="2" dy="2" result="offsetblur"/>
                <feComponentTransfer>
                  <feFuncA type="linear" slope="0.2"/>
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode/>
                  <feMergeNode in="SourceGraphic"/>
                </feMerge>
              </filter>
            </defs>

            <mask id="clipPath">
              <circle cx={midX} cy={midY} r="265" fill="#ffffff"/>
              <circle cx={midX} cy={midY} r="41.9" fill="#000000"/>
            </mask>

            <circle className="progress" cx={midX} cy={midY} r="230" mask="url(#clipPath)"/>

            <g className="timepoints" filter="url(#dropshadow)">
              {timePoints.map((timePoint) => drawTimepoint(timePoint, currentTimepoint.index))}
            </g>

            <g className="logo">
              <path className="e"
                    d="M286,306.49a25.48,25.48,0,0,1-6.14-11.68,23.5,23.5,0,0,0,12.78,35.1,23.35,23.35,0,0,0,13.1.27,34.43,34.43,0,0,0,25.45-31.52A34.21,34.21,0,0,0,330,288.07q-.17-.65-.36-1.26a24.91,24.91,0,0,1-.3,7A25.29,25.29,0,0,1,286,306.49Z"/>
              <path className="e"
                    d="M327,282.37c-2-6.53-7.13-12.19-14.41-15.95a34.39,34.39,0,0,0-40.06,6.25c-.32.32-.64.64-.94,1a25.21,25.21,0,0,1,31,1.18,25.27,25.27,0,0,1,.85,37.94A23.5,23.5,0,0,0,327,282.37Z"/>
              <path className="e"
                    d="M307.15,283.36A23.76,23.76,0,0,0,290.29,271a22.7,22.7,0,0,0-3.94-.33,23.46,23.46,0,0,0-21.92,14.86,31.81,31.81,0,0,0-2,11A34.4,34.4,0,0,0,286,329.63c.62.2,1.25.39,1.88.56.43.12.88.23,1.31.33a26,26,0,0,1-5.28-3.19,25.36,25.36,0,0,1-9.35-23.5,25.66,25.66,0,0,1,18.05-20.64A25.28,25.28,0,0,1,307.15,283.36Z"/>
            </g>
          </svg>

          <Time subpages={subpages} currentTimepoint={currentTimepoint}/>
        </div>
      </>
    );
  }
}
