import React, { Component } from "react";
import { withRouter } from "react-router";
import { withRoutingContextConsumer } from "../global/Contexts";

class ImageLink extends Component  {
  static defaultProps = {
    linkTo: ''
  };

  handleClick(event) {
    // event.stopPropagation();
    const { history, linkTo} = this.props;
    const { changeToPageAnimated } = this.props.routingContext;
    changeToPageAnimated(history, linkTo);
  }

  render() {
    return (
        <a href="#" onClick={(event) => this.handleClick(event)}>
          {this.props.children}
        </a>
    );
  }
}

export default withRoutingContextConsumer(withRouter(ImageLink));
