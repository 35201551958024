import React, { Component } from 'react';
import * as emailjs from 'emailjs-com';

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactGender: null,
      contactTopics: [],
      contactFirstName: null,
      contactLastName: null,
      contactEmail: null,
      contactComment: null,
      emailSend: false,
    }
  }

  onGenderChange(evt) {
    this.setState({ ...this.state, contactGender: evt.currentTarget.value });
  }

  onTopicChange(evt) {
    let contactTopics = this.state.contactTopics;

    const topicName = evt.currentTarget.name;
    if (evt.currentTarget.checked && !contactTopics.includes(topicName)) {
      contactTopics.push(topicName);
    }
    if (!evt.currentTarget.checked && contactTopics.includes(topicName)) {
      contactTopics = contactTopics.filter(item => topicName !== item);
    }

    this.setState({ ...this.state, contactTopics });
  }

  onFirstNameChange(evt) {
    this.setState({ ...this.state, contactFirstName: evt.currentTarget.value });
  }

  onLastNameChange(evt) {
    this.setState({ ...this.state, contactLastName: evt.currentTarget.value });
  }

  onEmailChange(evt) {
    this.setState({ ...this.state, contactEmail: evt.currentTarget.value });
  }

  onCommentChange(evt) {
    this.setState({ ...this.state, contactComment: evt.currentTarget.value });
  }

  checkValid() {
    const { contactGender, contactFirstName, contactLastName, contactEmail, contactComment } = this.state;

    const isNullOrEmpty = value => null === value || value.trim() === '';

    return !isNullOrEmpty(contactGender) &&
      !isNullOrEmpty(contactFirstName) &&
      !isNullOrEmpty(contactLastName) &&
      !isNullOrEmpty(contactEmail) &&
      !isNullOrEmpty(contactComment);
  }

  handleFormSubmit(evt) {
    if (!this.checkValid()) {
      return;
    }

    evt.preventDefault();
    const { contactGender, contactTopics, contactFirstName, contactLastName, contactEmail, contactComment } = this.state;
    const contactTopicsJoined = contactTopics.join(',');
    const contactCommentFormatted = contactComment.replace(/(\r\n|\n|\r)/gm, '<br>');

    const message = `Kontaktanfrage<br>Geschlecht:${contactGender}<br>Themen:${contactTopicsJoined}<br>Vorname:${contactFirstName}<br>Nachname:${contactLastName}<br>Email:${contactEmail}<br>Kommentar:<br>${contactCommentFormatted}`;

    let templateParams = {
      from_name: contactFirstName + ' ' + contactLastName,
      message_html: message,
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE,
      process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE,
      templateParams,
      process.env.REACT_APP_EMAILJS_USER
    );

    this.setState({ ...this.state, emailSend: true });
  }

  render() {
    const { emailSend } = this.state;
    return (
      <div className={"contact"}>
        <h4 className={"text-media-headline"}>Contact</h4>

        { !emailSend && <form className="contact-form">
          <div className="contact-radio-checkbox-container">
            <div>
              <fieldset className="radio-buttons">
                <h4 className="form-fieldset-headline">Anrede:</h4>
                <div className="form-fieldset">
                  <label className="form-radio-label">
                    <input name="pronoun" className="form-radio-field" type="radio" required value="male"
                           onChange={(evt => this.onGenderChange(evt))}/>
                    <div className="form-radio-button"/>
                    <span>männlich</span>
                  </label>
                  <label className="form-radio-label">
                    <input name="pronoun" className="form-radio-field" type="radio" required value="She"
                           onChange={(evt => this.onGenderChange(evt))}/>
                    <div className="form-radio-button"/>
                    <span>weiblich</span>
                  </label>
                  <label className="form-radio-label">
                    <input name="pronoun" className="form-radio-field" type="radio" required value="They"
                           onChange={(evt => this.onGenderChange(evt))}/>
                    <div className="form-radio-button"/>
                    <span>diverses</span>
                  </label>
                </div>
              </fieldset>

            </div>

            <div className="contact-form-adress">
              <h5>EURONET SOFTWARE AG</h5>
              <span>Augustinusstraße 9c</span>
              <span>50226 Frechen-Königsdorf</span>
              <div className="contact-form-adress-contact">
                <span>Rufnr. 02234 4000 0</span>
                <span>Faxnr. 02234 4000 80</span>

                <span> Email <a href="mailto:info@euronet-ag.de">info@euronet-ag.de</a></span>
              </div>
            </div>
          </div>


          <fieldset>
            <input type="text" id="firstName" required onChange={evt => this.onFirstNameChange(evt)}/>
            <label className="label" htmlFor="firstName">
              <span>Vorname</span>
            </label>
          </fieldset>

          <fieldset>
            <input type="text" id="lastName" required onChange={evt => this.onLastNameChange(evt)}/>
            <label className="label" htmlFor="lastName">
              <span>Name</span>
            </label>
          </fieldset>

          <fieldset>
            <input type="text" id="email" required onChange={evt => this.onEmailChange(evt)}/>
            <label className="label" htmlFor="email">
              <span>E-Mail</span>
            </label>
          </fieldset>

          <fieldset>
            <textarea id="comments" required onChange={evt => this.onCommentChange(evt)}/>
            <label className="label" htmlFor="comments">
              <span>Kommentar</span>
            </label>
          </fieldset>

          <button onClick={evt => this.handleFormSubmit(evt)}>Senden</button>
        </form> }

        { emailSend && <span>Ihre Kontaktanfrage wurde erfolgreich verschickt</span> }

      </div>
    );
  }
}
