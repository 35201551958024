import React, {Component} from 'react';
import TextMediaImage from "./TextMediaImage";

export default class TextMediaModule extends Component {
  static defaultProps = {
    title: '',
    imageTitle: '',
    srcImage: '',
    positionImage: '',
    description: '',
    imageSize: ''
  };

  render() {
    let positionTextMedia = this.props.positionImage;
    let {title, srcImage, imageSize, imageTitle, description } = this.props;

    return (
        <div className={"text-media-module"}>
          <div className={`text-media-container${positionTextMedia === 'right' ? ' image-right' : ''}`}>
            <TextMediaImage srcImage={srcImage} classNames={imageSize} imageTitle={imageTitle}/>
            <div className={"text-media-description w-50"}>
              <h4 className="text-media-headline">{title}</h4>
              {description}
            </div>
          </div>
        </div>
    );
  }
}
