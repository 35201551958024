import React from "react";
const AppContext = React.createContext('appContext');

const withAppContextConsumer = WrappedComponent => props => ( <AppContext.Consumer>
    {(context) => {
      return <WrappedComponent {...props} appContext={context} />
    }}
  </AppContext.Consumer>
);

const RoutingContext = React.createContext('routingContext');

const withRoutingContextConsumer = WrappedComponent => props => ( <RoutingContext.Consumer>
    {(context) => {
      return <WrappedComponent {...props} routingContext={context} />
    }}
  </RoutingContext.Consumer>
);

export {
  AppContext,
  withAppContextConsumer,
  RoutingContext,
  withRoutingContextConsumer
};
