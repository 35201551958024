import React, { Component } from 'react';
import { withRouter } from "react-router";
import data from "../assets/data/data";

import ImageModule from "./ImageModule";
import TextMediaModule from "./TextMediaModule";
import TextModule from "./TextModule";
import Teaser from "./Teaser";
import Footer from "./Footer";
import BulletpointModule from "./BulletpointModule";
import Button from "./Button";
import { withAppContextConsumer } from "../global/Contexts";

class ArticleSubpage extends Component  {

  componentDidMount() {
    const {changeMainClass, changeTitle} = this.props.appContext;
    const subpage = this.getSubpage();

    changeTitle(`Der Tag mit Euronet - ${subpage.title}`);
    changeMainClass('is-subpage');
  }

  componentWillUnmount() {
    this.props.appContext.changeMainClass('');
  }

  getSubpage() {
    const {match} = this.props;

    const filteredSubpages = data.subpages.filter(
      (subpage) => subpage.title === match.params.title
    );

    return filteredSubpages[0];
  }

  render() {
    const subpage = this.getSubpage();

    let prevIndex = subpage.id - 1;
    let nextIndex = subpage.id + 1;

    if(prevIndex === -1) {
      prevIndex = data.subpages.length - 1;
    }

    if(nextIndex === data.subpages.length) {
      nextIndex = 0;
    }

    const previousSubpage = data.subpages[prevIndex];
    const nextSubpage = data.subpages[nextIndex];

    return (
      <div className={"category__content"}>
        <div>
          <section className="category__content--section" key={subpage.id}>
            <div className={"additional-information"}>
              <h1 className={"headline-subpage"}>{subpage.title}</h1>
              <div className={"subpage-description"}>
                <p>{subpage.description}</p>
              </div>
            </div>

            <div className={"headline-claim-subpage"}>
              <div>
                <div className={"claim"}>{subpage.claimSubpage}</div>
                <h3>{subpage.subtitle}</h3>
              </div>
              <Button
                  buttonTitle={"Zurück"}
                  linkTo={"/home"}
                  iconPosition={'before'}
                  direction={'left'}
                  buttonStyle={''}
              />
            </div>
          </section>

          <section className={"parallax-background first"}
                   style={{backgroundImage: `url(${subpage.parallaxImageTop})`}}/>

          <section className={"section-wrapper"}>
            { subpage.modules.map((module, i) => {
              if(module.type === 'imageModule') {
                return <ImageModule key={i}
                                    srcImage={module.src}
                                    classNames={module.classNames}
                                    imageTitle={module.imageTitle}
                />
              }

              if (module.type === 'textMediaModules') {
                return <TextMediaModule
                    key={i}
                    title={module.textMediaTitle}
                    positionImage={module.positionImage}
                    imageSize={module.imageSize}
                    srcImage={module.textMediaImage.src}
                    imageTitle={module.textMediaImage.imageTitle}
                    description={module.textMediaDescription}
                />
              }

              if (module.type === 'textModules') {
                return <TextModule
                    key={i}
                    title={module.textModuleTitle}
                    description={module.textDescription}
                    textWidth={module.textWidth}
                />
              }

              if (module.type === 'bulletpointModule') {
                return <BulletpointModule
                    key={i}
                    title={module.title}
                    description={module.textDescription}
                    textWidth={module.textWidth}
                    bulletpointList={module.bulletpointList}
                />
              }

              if(module.type === 'teaserList') {
                return (
                    <Teaser
                        key={i}
                        title={module.teaserTitle}
                        srcImage={module.teaserImage.srcImage}
                        titleImage={module.teaserImage.imageTitle}
                        description={module.teaserText}
                        moduleWidth={module.teaserWidth}
                    />
                );
              }
            }) }
          </section>

          {subpage.parallaxImageBottom && <section className="parallax-background second"
                   style={{backgroundImage: `url(${subpage.parallaxImageBottom})`}}/>}

          <Footer
              previousSubpage={previousSubpage}
              nextSubpage={nextSubpage}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(withAppContextConsumer(ArticleSubpage));
