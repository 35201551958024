import React, { Component } from 'react';
import Panel from "./components/Panel";
import ArticleContainer from "./components/ArticleContainer";
import data from "./assets/data/data";
import Article from "./components/Article";
import $ from "jquery";
import {gsap} from "gsap/all";
import AnimationState from "./global/AnimationState";
import { withAppContextConsumer } from  "./global/Contexts";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      imagePositions: []
    };
  }

  componentDidMount() {
    const {changeMainClass, changeTitle} = this.props.appContext;
    changeMainClass('is-home');
    changeTitle("Der Tag mit Euronet - Euronet AG");


    window.addEventListener("blur", () => {
      AnimationState.isPaused = true;
    });
    window.addEventListener("focus", () => {
      AnimationState.isPaused = false;
    });

    AnimationState.isStopped = window.innerWidth < 768;
    window.addEventListener("resize", () => {
      AnimationState.isStopped = window.innerWidth < 768;
    });

    const
        clockProgress = $('svg .progress'),
        infoText = $('.article-wrapper.active .article-information .additional-information'),
        infoHeadline = $('.article-wrapper.active .article-information .big-headline-wrapper'),
        articleImage = $('.article-wrapper.active .article-image .img-holder');

    gsap.set(clockProgress, {drawSVG: '0 0', rotation: '-=90deg', transformOrigin: '50% 50%'});

    const fadeInAni = gsap.timeline();
    fadeInAni
        .addLabel('start', 0)
        .to(articleImage, {
          duration: 8,
          scale: '1.05',
          ease: 'linear'
        }, 'start')
        .from(infoHeadline, {duration: 1.2, y: '+=150px', autoAlpha: 0, ease: 'Power2.easeOut'}, 'start+=0.1')
        .from(infoText, {duration: 1.2, y: '+=150px', autoAlpha: 0, ease: 'Power2.easeOut'}, 'start+=0.2')
    ;
  }

  initFirstTimePoint(firstTimepoint, secondTimepoint) {
    const panels = $('.panel'),
        panel_1 = $('.panel-1'),
        panel_2 = $('.panel-2');

    gsap.set(panels, {autoAlpha: 0, x: '-50%', y: '-50%', transformOrigin: '100% 50%'});
    gsap.set(panel_1, {zIndex: 4, backgroundColor: firstTimepoint.color});
    gsap.set(panel_2, {zIndex: 2, backgroundColor: secondTimepoint.color});

    this.setState({
      ...this.state,
      currentIndex: firstTimepoint.index,
    });
  }

  resetAni(imagePosition) {
    const panels = $('.panel'),
        panel_1 = $('.panel-1'),
        panel_2 = $('.panel-2');

    gsap.set(panels, {rotation: '0'});
    gsap.set(panel_1, {zIndex: 4});
    gsap.set(panel_2, {zIndex: 2});

    const setAniImgLeft = () => {
      gsap.set(panels, {x: '50%', transformOrigin: '0% 50%'});
    };

    const setAniImgRight = () => {
      gsap.set(panels, {x: '-50%', transformOrigin: '100% 50%'});
    };

    if (imagePosition === 'left') {
      setAniImgLeft();
    } else {
      setAniImgRight();
    }

    AnimationState.isAnimating = false;
  }

  handleNewTimepoint(timePoint, oldTimePoint, imagePositions) {
    const
        articleInfoActive = $('.article-wrapper.active .article-information'),
        articleImageActive = $('.article-wrapper.active .article-image img'),

        panel_1 = $('.panel-1'),
        panel_2 = $('.panel-2'),

        imagePosition = imagePositions[timePoint.index],
        spinChangeAni = gsap.timeline({
          defaults: {duration: 0},
          onComplete: () => this.resetAni(imagePosition)
        })
    ;

    AnimationState.isAnimating = true;

    let spinDirection = timePoint.index > oldTimePoint.index ? '+=180deg' : '-=180deg';

    const articleChange = () => {
      this.setState({
        ...this.state,
        currentIndex: timePoint.index,
        currentColor: timePoint.color,
        imagePositions: imagePositions,
      });

      const infoText = $('.article-wrapper.active .article-information .additional-information'),
          infoHeadline = $('.article-wrapper.active .article-information .big-headline-wrapper'),
          articleImage = $('.article-wrapper.active .article-image img');

      const fadeInAni = gsap.timeline();
      fadeInAni
          .addLabel('start', 0)
          .to(articleImage, {
            duration: 8,
            scale: '1.05',
            ease: 'linear'
          }, 'start')
          .from(infoHeadline, {duration: 1.2, y: '+=150px', autoAlpha: 0, ease: 'Power2.easeOut'}, 'start+=0.1')
          .from(infoText, {duration: 1.2, y: '+=150px', autoAlpha: 0, ease: 'Power2.easeOut'}, 'start+=0.2')
      ;
    };

    spinChangeAni
        .addLabel('start', 0)
        .to(articleInfoActive, {zIndex: 3}, 'start')
        .to(panel_2, {background: timePoint.color, autoAlpha: 1}, 'start')

        .addLabel('step2', '>')
        .to(panel_2, {duration: 0.5, rotation: spinDirection, ease: 'power1.in'}, 'step2')
        .to(panel_1, {duration: 0.5, autoAlpha: 1}, 'step2')

        .addLabel('step3', '>')
        .to(panel_2, {zIndex: 4}, 'step3')
        .to(panel_1, {zIndex: 3}, 'step3')
        .to(articleInfoActive, {zIndex: 2}, 'step3')

        .add(articleChange, null, "-=0.12")
        .to(articleImageActive, {scale: 1})

        .to(panel_1, {duration: 0.5, rotation: spinDirection, ease: 'power1.out'})
        .to(panel_1, {autoAlpha: 0, background: timePoint.color})
        .to(panel_2, {duration: 0.5, autoAlpha: 0})
    ;
  }

  render() {
    const { currentIndex, imagePositions, currentColor } = this.state;
    const currentImagePosition = imagePositions.length ? imagePositions[currentIndex] : 'null';

    return (
    <div className={`article-container`}>
      <Panel imagePosition={currentImagePosition} color={currentColor}/>

      <ArticleContainer
          handleNewTimepoint={(timePoint, oldTimePoint, imagePosition) => this.handleNewTimepoint(timePoint, oldTimePoint, imagePosition)}
          initFirstTimePoint={(firstTimepoint, secondTimepoint, imagePositions) => this.initFirstTimePoint(firstTimepoint, secondTimepoint, imagePositions)}
          subpages={data.subpages}
      >
        {
          data.subpages.map((subpage, i) =>
              <Article key={i}
                       cat={subpage.cat}
                       claim={subpage.claim}
                       title={subpage.title}
                       dataTime={subpage.dataTime}
                       mobileTime={subpage.dataTime}
                       dataColor={subpage.dataColor}
                       subtitle={subpage.subtitle}
                       articleImage={subpage.articleImage}
                       isActive={currentIndex === i}
                       imagePosition={imagePositions.length ? imagePositions[i] : 'right'}
              />
          )
        }
      </ArticleContainer>
    </div>
    );
  }
}

export default withAppContextConsumer(Home);
